import styled from "styled-components"
import { device } from "../../utils/breakpoints"
import { FlexGrid } from "components/FlexGrid"

export const MegaMenuGrid = styled(FlexGrid)`
  @media ${device.desktop} {
    box-shadow: 0 25px 35px 0 rgba(0, 0, 0, 0.35);
    transition: 100ms;
    background: white;
    border-radius: 2px;
    bottom: -12px;
  }
`

export const MenuSection = styled.ul`
  list-style: none;
  margin: 0;
  padding: 35px 38px 28px 38px;
  width: 100%;
  border-right: 1px solid rgba(220, 224, 231, 0.6);

  @media ${device.desktop} {
    height: 100%;
  }
`

export const MenuItem = styled.li`
  padding-bottom: 15px;
  position: relative;
  z-index: 1;

  @media ${device.desktop} {
    line-height: 130%;
  }

  @media ${device.large} {
    line-height: 150%;
  }

  a {
    font-weight: 600;
    font-size: 22px;
    color: ${props => props.theme.navy} !important;
    transition: 120ms;
    z-index: 2;
    :hover {
      color: ${props => props.theme.peach} !important;
    }

    :before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export const SubmenuMenuItem = styled(MenuItem)`
  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-6px);
    width: 120%;
    height: 100%;
    background: ${props => props.theme.peach};
    border-radius: 30px;
    transition: 120ms;
    z-index: 0;
    opacity: 0;
    z-index: -1;
  }
  :hover {
    a {
      color: #fff !important;
    }
    :after {
      opacity: 1;
    }
  }

  a {
    font-size: 16px;
    padding-bottom: 10px;
  }
`
