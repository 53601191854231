import React from "react"
import { NavBar } from "../NavBar"
import { HeaderContainer, HeroImage, HeaderTextWrapper } from "./style"
import { MainColumn } from "components/MainColumn"
import { Breadcrumbs } from "../Breadcrumbs"

const Header = ({
  title,
  description,
  img,
  internal,
  ancestors,
  preview,
  stickyNavItems,
  landing,
  campaign,
  home,
  depth,
}) => {
  return (
    <HeaderContainer
      home={home}
      landing={landing}
      img={img}
      internal={internal}
      campaign={campaign}
    >
      {img && (
        <HeroImage
          campaign={campaign}
          internal={internal}
          fluid={{
            ...img,
            sizes: "(min-width: 1660px) 1660px, 100vw",
          }}
        />
      )}
      <MainColumn>
        <NavBar
          stickyNavItems={stickyNavItems}
          preview={preview}
          campaign={campaign}
        />

        <HeaderTextWrapper internal={internal} campaign={campaign}>
          {internal && depth !== 0 && <Breadcrumbs ancestors={ancestors} />}
          {!landing && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
          <p>{description}</p>
        </HeaderTextWrapper>
      </MainColumn>
    </HeaderContainer>
  )
}

export default Header
