import React from "react"
import { BreadcrumbsContainer } from "./style"
import { ReactComponent as Home } from "images/icons/home-icon.svg"
import { Link } from "gatsby"

const Breadcrumbs = ({ ancestors }) => {
  return (
    <BreadcrumbsContainer>
      <Link to="/">
        <Home />
      </Link>
      {ancestors &&
        ancestors
          .slice()
          .reverse()
          .map((item, index) => {
            return (
              <Link
                key={index}
                to={item.link}
                className="underline-thin"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            )
          })}
    </BreadcrumbsContainer>
  )
}

export default Breadcrumbs
