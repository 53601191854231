import React from "react"
import { ReactComponent as Art } from "../../images/icons/art.svg"
import { ReactComponent as Search } from "../../images/icons/search.svg"
import { ReactComponent as IndustrialDesign } from "../../images/icons/industrial_design.svg"
import { ReactComponent as MailIcon } from "../../images/icons/mail.svg"
import { ReactComponent as MessageIcon } from "../../images/icons/message.svg"
import { ReactComponent as TitleIcon } from "../../images/icons/title.svg"
import { ReactComponent as NameIcon } from "../../images/icons/name.svg"
import { ReactComponent as PhoneIcon } from "../../images/icons/phone.svg"
import { ReactComponent as ArrowLeft } from "../../images/icons/arrow-left.svg"
import { ReactComponent as Wink } from "../../images/icons/wink.svg"
import { ReactComponent as HandShake } from "../../images/icons/handshake.svg"
import { ReactComponent as Awards } from "../../images/icons/awards.svg"
import { ReactComponent as PhoneColor } from "../../images/icons/phoneColor.svg"
import { ReactComponent as Home } from "../../images/icons/home.svg"
import { ReactComponent as Contact } from "../../images/icons/contact.svg"
import { ReactComponent as StaffPhone } from "../../images/icons/staff_phone.svg"
import { ReactComponent as StaffEmail } from "../../images/icons/staff_email.svg"
import { ReactComponent as Linkedin } from "../../images/icons/linkedin.svg"

const iconMap = {
  art: { icon: Art },
  "industrial-design": { icon: IndustrialDesign },
  search: { icon: Search },
  mail: { icon: MailIcon },
  message: { icon: MessageIcon },
  title: { icon: TitleIcon },
  name: { icon: NameIcon },
  phone: { icon: PhoneIcon },
  "arrow-left": { icon: ArrowLeft },
  wink: { icon: Wink },
  handshake: { icon: HandShake },
  trophy: { icon: Awards },
  "phone-color": { icon: PhoneColor },
  home: { icon: Home },
  contact: { icon: Contact },
  "staff-phone": { icon: StaffPhone },
  "staff-email": { icon: StaffEmail },
  linkedin: { icon: Linkedin },
}

export const hasIcon = name => {
  return !!iconMap[name]
}

export const Icon = ({ name, className }) => {
  return iconMap[name]
    ? React.createElement(iconMap[name].icon, { className: className || name })
    : false
}
