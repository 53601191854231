import React from "react"
import { Link } from "gatsby"
import { NavListItem, Submenu, NavItemLink } from "./style"

import { MegaMenu } from "components/MegaMenu"

const NavItem = ({ item, setMenuShown, menuShown }) => {
  const hasChild = item.childPages.nodes.length !== 0
  return (
    <NavListItem megamenu={item.megaMenu.megaMenu} childpages={hasChild}>
      <NavItemLink
        to={item.link}
        megamenu={item.megaMenu.megaMenu}
        childpages={hasChild}
      >
        <span dangerouslySetInnerHTML={{ __html: item.title }} />
      </NavItemLink>
      {item.megaMenu.megaMenu ? (
        <MegaMenu menuData={item.childPages.nodes} />
      ) : (
        <Submenu>
          <ul>
            {!item.megaMenu.megaMenu &&
              item.childPages.nodes.map((submenu, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={submenu.link}
                      dangerouslySetInnerHTML={{ __html: submenu.title }}
                    />
                  </li>
                )
              })}
          </ul>
        </Submenu>
      )}
    </NavListItem>
  )
}

export default NavItem
