import React from "react"

import { SocialLinksContainer } from "./style.js"

import { List } from "../List/index"

import { ReactComponent as FacebookLogo } from "./facebook-logo.svg"
import { ReactComponent as YoutubeLogo } from "./youtube.svg"
import { ReactComponent as LinkedInLogo } from "./linkedin.svg"

const SocialLinks = props => {
  return (
    <SocialLinksContainer footer={props.footer}>
      <List direction="horizontal">
        <a
          href="https://www.linkedin.com/company/johnston-grocke-group/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInLogo />
        </a>
        <a
          href="https://www.facebook.com/JohnstonGrockeGroup/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookLogo />
        </a>
        <a
          href="https://www.youtube.com/user/JohnstonGrocke/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutubeLogo />
        </a>
      </List>
    </SocialLinksContainer>
  )
}

export default SocialLinks
