import React from "react"
import { MegaMenuGrid, MenuSection, MenuItem, SubmenuMenuItem } from "./style"
import { MegaMenuContainer } from "../NavBar/style"
import { FlexRow, FlexCol } from "components/FlexGrid"
import { Link } from "gatsby"

const MegaMenu = ({ menuData }) => {
  return (
    <MegaMenuContainer>
      <MegaMenuGrid fullWidth gap={[0]}>
        <FlexRow>
          {menuData.map((menu, index) => {
            return (
              <FlexCol xs={4} key={index}>
                <MenuSection>
                  <MenuItem>
                    <Link
                      to={menu.link}
                      dangerouslySetInnerHTML={{
                        __html: menu.title,
                      }}
                    />
                  </MenuItem>
                  {menu.childPages.nodes.map((submenu, index) => {
                    return (
                      <SubmenuMenuItem key={index}>
                        <Link
                          to={submenu.link}
                          dangerouslySetInnerHTML={{
                            __html: submenu.title,
                          }}
                        />
                      </SubmenuMenuItem>
                    )
                  })}
                </MenuSection>
              </FlexCol>
            )
          })}
        </FlexRow>
      </MegaMenuGrid>
    </MegaMenuContainer>
  )
}

export default MegaMenu
