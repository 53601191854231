import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"
import { FlexGrid, FlexCol } from "components/FlexGrid"
import { Form } from "components/Form"
import { TextInput } from "components/Form/TextInput"
import { CustomButton1 } from "components/customButtons"

export const SignUpSectionContainer = styled(FlexGrid)`
  border-bottom: 1px solid rgba(220, 224, 231, 1);
  padding-bottom: ${props => (props.campaign ? "0" : "40px")};

  @media ${device.desktop} {
    padding-bottom: 46px;
  }
`

export const TopSection = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: ${props => (props.campaign ? "25px" : "30px")};

  @media ${device.desktop} {
    margin-bottom: 45px;
  }
  :after {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 5px;
    left: 145px;
    width: 100%;
    background: ${props => props.theme.peach};
    @media ${device.desktop} {
      left: 213px;
    }
  }
  svg.logo {
    display: block;
    height: 18px;
    width: 135px;

    @media ${device.desktop} {
      height: 26px;
      width: 196px;
    }
    > g {
      :first-of-type {
        path {
          fill: ${props => props.theme.navy};
        }
      }
    }
  }
`
export const SignUpTextContainer = styled.div`
  width: 100%;

  @media ${device.desktop} {
    padding-right: 25px;
    ${props =>
      props.campaign &&
      ` > div {
             display: flex;
             flex-direction: row;
             justify-content: space-between;
            h3 {
              width: 48%;
            }
            > div {
              width: 48%;
            }
            }`}
  }
  h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: ${props => (props.campaign ? "15px" : "20px")};
    line-height: 130%;

    @media ${device.desktop} {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 35px;
    @media ${device.desktop} {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  a {
    font-weight: 600;
    color: ${props => props.theme.navy};
    display: inline-block;
    position: relative;
    :before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      opacity: 1;
      transition: 120ms;
      background: ${props => props.theme.peach};
    }
    :hover {
      :before {
        opacity: 0;
      }
    }
  }
`

export const SignUpFormContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  border-radius: 2px;
  width: 100%;
  padding: 30px;
  padding-bottom: 35px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.submitted && "center"};

  @media ${device.desktop} {
    margin-left: 20px;
    padding: 38px 35px;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 17px;
    margin-bottom: ${props => props.submitted && 0};

    line-height: 120%;
    line-height: ${props => props.submitted && "150%"};

    @media ${device.desktop} {
      font-size: 24px;
      color: #2a3449;
      letter-spacing: -0.5px;
      margin-bottom: 13px;
    }
  }

  p {
    display: ${props => props.submitted && "none"};
    font-size: 14px;
    margin-bottom: 20px;
    @media ${device.desktop} {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
`

export const SignUpForm = styled(Form)`
  @media ${device.desktop} {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
  }
`

export const EmailInput = styled(TextInput)`
  background: #eff1f5;
  height: 48px !important;
  border-radius: 2px;
  box-shadow: none;

  @media ${device.desktop} {
    display: inline-flex;
    flex-grow: 1;
    margin-right: 30px;
    margin-bottom: 20px;
    width: calc(100% - 100px - 30px);

    :last-of-type {
      margin-bottom: 0;
    }
  }

  input {
    ::placeholder {
      font-size: 17px !important;
      font-weight: 500;
      color: #5d6371;
      opacity: 1;
    }
  }

  .error {
    margin: 0;
    bottom: -22px;
    /* color: rgb(147, 0, 0); */
    @media ${device.desktop} {
      bottom: -29px;
    }
  }
`

export const SubmitButton = styled(CustomButton1)`
  @media ${device.desktop} {
    width: 100px;
    align-self: flex-end;
  }
`

export const RHSFlexCol = styled(FlexCol)`
  display: ${props => props.campaign && "none"};
`
