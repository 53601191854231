import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import useMedia from "hooks/useMedia.js"
import { Icon } from "utils/helpers/getIcon"
import NavItem from "./navItem"
import Hamburger from "./hamburger"
import { ReactComponent as Logo } from "../../images/johnston-grocke-logo.svg"

import {
  LogoWrapper,
  NavBarContainer,
  NavWrapper,
  Nav,
  NavList,
  NavIcon,
  StickyNav,
  DesktopPhone,
  PhoneContact,
  ContactButton,
  EmailContact,
} from "./style"

const NavBar = ({ preview, stickyNavItems, campaign }) => {
  const [isNavShown, setNavShown] = useState(false)
  const isMobile = useMedia(["(max-width: 768px)"], [true], false)
  // const rightArrow = <RightArrow />

  const data = useStaticQuery(
    graphql`
      {
        wpgraphql {
          pages(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
            nodes {
              title
              link
              megaMenu {
                megaMenu
                hideMainMenu
              }
              childPages(
                where: { orderby: { field: MENU_ORDER, order: ASC } }
              ) {
                nodes {
                  title
                  link
                  childPages(
                    where: { orderby: { field: MENU_ORDER, order: ASC } }
                  ) {
                    nodes {
                      title
                      link
                    }
                  }
                }
              }
            }
          }
          siteOptions {
            siteSettings {
              phoneNumber
            }
          }
        }
      }
    `
  )
  const navBarData = preview?.mainMenu.nodes || data.wpgraphql.pages.nodes
  const filteredNavData = navBarData.filter(item => !item.megaMenu.hideMainMenu)
  const phoneNumber =
    preview?.footerData?.siteOptions?.siteSettings?.phoneNumber ||
    data.wpgraphql.siteOptions.siteSettings.phoneNumber

  return (
    <NavBarContainer>
      <NavWrapper>
        {!campaign && (
          <>
            <Nav isNavShown={isNavShown}>
              <NavList>
                {filteredNavData.map((item, index) => {
                  return <NavItem key={index} item={item} />
                })}
              </NavList>
            </Nav>
            <Hamburger
              isNavShown={isNavShown}
              toggleShowNav={() => setNavShown(!isNavShown)}
            />
          </>
        )}

        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>

        <NavIcon campaign={campaign}>
          <PhoneContact href={`tel: ${phoneNumber}`}>
            <Icon name="phone" />
            <DesktopPhone campaign={campaign}>
              Call <span>{phoneNumber}</span>{" "}
              <span className="second__text">OR </span>
            </DesktopPhone>
          </PhoneContact>
          <EmailContact href="mailto: abc@example.com" campaign={campaign}>
            <Icon name="contact" />
          </EmailContact>
          <ContactButton label="Contact us" link="/contact/" />
        </NavIcon>
      </NavWrapper>

      {stickyNavItems && (
        <StickyNav isMobile={isMobile}>
          {stickyNavItems.map((item, index) => {
            if (!item) return null

            return (
              <Link to={item.link} key={index}>
                <span dangerouslySetInnerHTML={{ __html: item.title }} />
              </Link>
            )
          })}
        </StickyNav>
      )}
    </NavBarContainer>
  )
}

export default NavBar
