import styled from "styled-components"
import { Link } from "gatsby"
import { NewsButton } from "../customButtons"
import { FlexGrid, FlexCol } from "../FlexGrid/index"

import { device } from "../../utils/breakpoints"

export const ItemLink = styled(Link)`
  margin-right: 30px;
  margin-bottom: 10px !important;
  cursor: pointer;
  position: relative;

  @media ${device.tablet} {
    margin-bottom: 17px !important;
  }
`
export const Item = styled.h4`
  font-weight: 400;
  font-size: 12px;
  color: #9cacae;
  letter-spacing: 0;
  line-height: 100% !important;
  display: inline !important;
  position: relative;

  @media ${device.desktop} {
    font-size: 16px;
    color: #2a3449;
    letter-spacing: -0.25px;
    line-height: 32px;
  }
  a:after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.linkColor};
    opacity: 0;
    transition: 90ms;
  }

  a:hover {
    :after {
      opacity: 1;
    }
  }
`

export const NewFlexGrid = styled(FlexGrid)`
  padding-bottom: 105px;
  margin-top: 35px;
  width: 100%;

  @media ${device.tablet} {
    margin-top: 51px;
  }
`

export const Menu = styled.div`
  display: flex;
  align-items: flex-end;
`

export const NewFlexCol = styled(FlexCol)`
  margin-bottom: 0 !important;

  :last-child {
    margin-bottom: 20px;
  }
  :nth-child(3n) {
    margin-top: 8px;
    margin-bottom: 35px !important;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`
export const FlexColMenu = styled(FlexCol)`
  margin-bottom: 0 !important;
`
export const FlexColLast = styled(FlexCol)`
  border-top: 1px solid #dce0e7;

  @media ${device.tablet} {
    border-top: none;
  }
  @media ${device.desktop} {
    padding: 0 !important;
  }
`
export const NewNewsButton = styled(NewsButton)`
  font-size: 12px;
  letter-spacing: -0.08px;
  text-align: center;
  line-height: 27px;
  margin-top: 0;
  margin-left: 0;
  padding: 8px 15px;
  height: 36px;
  max-width: 108px;

  @media ${device.desktop} {
    font-size: 16px !important;
    letter-spacing: -0.09px;
    width: 136px;
    max-width: 100%;
    margin-top: 8px;
    padding: 8px 10px;
    margin-left: 0;
    height: 48px;
    order: 2;
  }
`
export const Text = styled.p`
  font-size: 12px;
  color: #5c6370;
  letter-spacing: -0.19px;
  line-height: 24px;
  padding-top: 30px;
  margin-top: 35px;
  border-top: 1px solid #dce0e7;

  @media ${device.desktop} {
    font-size: 14px;
    letter-spacing: -0.22px;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 20px;
    order: 1;
    border-top: none;
  }

  span :before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #f17071;
    display: inline-block;
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 2px;
  }

  a {
    font-weight: 600;
    color: #5c6370;
    position: relative;
    display: inline-block;
    transition: 110ms;
    opacity: 1;

    :hover {
      opacity: 0.7;
    }
  }
`
export const Addreses = styled.div`
  @media ${device.tablet} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  @media ${device.large} {
    max-width: 432px;
  }
`
export const Location = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
  padding-top: 35px;
  position: relative;

  @media ${device.large} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
  align-items: middle;
  margin-bottom: 22px;

  :first-child {
    margin-bottom: 16px;
  }

  @media ${device.tablet} {
    flex-direction: row;
    :first-child {
      margin-right: 25px;
      margin-bottom: 22px;
    }
  }

  a {
    font-weight: 600;
    font-size: 14px;
    color: #2a3449;
    letter-spacing: -0.22px;
    line-height: 20px;
    position: relative;
    display: block;

    @media ${device.desktop} {
      font-size: 16px;
      letter-spacing: -0.25px;
      line-height: 24px;
    }

    ::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${props => props.theme.linkColor};
      opacity: 1;
      transition: 90ms;

      @media ${device.desktop} {
        bottom: 0;
      }
    }
    :hover {
      :after {
        opacity: 0;
      }
    }
  }
`
export const NewIcon = styled.div`
  width: 14px;
  margin-right: 15px;
  max-height: 16px;

  @media ${device.desktop} {
    align-self: flex-start;
    padding-top: 5px;
  }
`
