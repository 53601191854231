import React from "react"
import FooterMenu from "./footerMenu"
import { useStaticQuery, graphql } from "gatsby"

import { Container } from "./footerStyles"
import SignUpSection from "./SignUpSection"

const Footer = ({ preview, campaign }) => {
  const data = useStaticQuery(
    graphql`
      {
        wpgraphql {
          menus(where: { slug: "footer" }) {
            nodes {
              name
              menuItems {
                nodes {
                  label
                  url
                }
              }
            }
          }
          siteOptions {
            siteSettings {
              title
              phoneNumber
              mapLink
              lefttext
              fieldGroupName
              email
              description
              address
              acknowledgement
            }
          }
        }
      }
    `
  )

  const newData = preview?.footerData || data.wpgraphql
  const signUpData = newData.siteOptions.siteSettings
  console.log("signUpData:", signUpData)

  return (
    <Container>
      <SignUpSection signUpData={signUpData} campaign={campaign} />
      <FooterMenu newData={newData} campaign={campaign} />
    </Container>
  )
}
export default Footer
