import React from "react"
import { Icon } from "../../utils/helpers/getIcon"
import { SocialLinks } from "../SocialLinks"

import {
  ContactUsContainer,
  NewNewsButton,
  Text,
  NewIcon,
  Addreses,
  Location,
} from "./footerMenuStyles"

const FooterMenu = ({ newData }) => {
  const phoneIcon = <Icon name="phone-color" />
  const homeIcon = <Icon name="home" />

  return (
    <Location>
      <Addreses>
        <ContactUsContainer>
          <NewIcon>{homeIcon}</NewIcon>
          <a
            href={newData.siteOptions.siteSettings.mapLink}
            target="_blank"
            rel="noopener noreferrer"
            className="first"
          >
            {newData.siteOptions.siteSettings.address}
          </a>
        </ContactUsContainer>

        <ContactUsContainer>
          <NewIcon>{phoneIcon}</NewIcon>
          <a href={`tel:${newData.siteOptions.siteSettings.phoneNumber}`}>
            {newData.siteOptions.siteSettings.phoneNumber}
          </a>
        </ContactUsContainer>
      </Addreses>
      <NewNewsButton label="Contact us" link="/contact/" />

      <SocialLinks />

      <Text>
        {newData.siteOptions.siteSettings.acknowledgement}
        <span>
          <a href={newData.menus.nodes[0].menuItems.nodes[0].url}>
            {newData.menus.nodes[0].menuItems.nodes[0].label}
          </a>
        </span>
        <span>
          <a
            href={newData.menus.nodes[0].menuItems.nodes[1].url}
            target="blank"
            rel="noopener noreferrer"
          >
            {newData.menus.nodes[0].menuItems.nodes[1].label}
          </a>
        </span>
      </Text>
    </Location>
    // <NewFlexGrid
    //   className={props.className}
    //   gap={[0, 10, 30]}
    //   rowGap={[30, 50, 60]}
    //   fullWidth
    // >
    //   <FlexRow>
    //     <NewFlexCol xs={12} md={3} lg={3}>
    //       <Location>
    //         <ContactUsContainer>
    //           <NewIcon>{homeIcon}</NewIcon>
    //           <a
    //             href="https://goo.gl/maps/asdtN83Ab67SZrJC8"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             290 Glen Osmond Rd, Fullarton SA 506
    //           </a>
    //         </ContactUsContainer>

    //         <ContactUsContainer>
    //           <NewIcon>{phoneIcon}</NewIcon>
    //           <a href="tel:(08) 8303 0300">08 8303 0300</a>
    //         </ContactUsContainer>

    //         <NewNewsButton label="Contact us" />
    //       </Location>
    //     </NewFlexCol>

    //     <FlexColMenu xs={12} md={3} lg={3}>
    //       <Menu title="About Us">
    //         <Item>
    //           <ItemLink to={"/"}>Why Johnston Grocke</ItemLink>
    //         </Item>
    //         <Item>
    //           <ItemLink to={"/"}>Meet the team</ItemLink>
    //         </Item>
    //         <Item>
    //           <ItemLink to={"/"}>Success Stories</ItemLink>
    //         </Item>
    //       </Menu>
    //     </FlexColMenu>
    //     <FlexColMenu xs={12} md={3} lg={3}>
    //       <Menu title="OUR SERVICES">
    //         <Item>
    //           <ItemLink to={"/"}>Wealth Creation</ItemLink>
    //         </Item>
    //         <Item>
    //           <ItemLink to={"/"}>Financial Planning</ItemLink>
    //         </Item>
    //         <Item>
    //           <ItemLink to={"/"}>Property & Finance</ItemLink>
    //         </Item>
    //         <Item>
    //           <ItemLink to={"/"}>Business Advisory</ItemLink>
    //         </Item>
    //       </Menu>
    //     </FlexColMenu>
    //     <FlexColMenu xs={12} md={3} lg={3}>
    //       <Menu title="Lyfe Membership">
    //         <Item>
    //           <ItemLink to={"/"}>Service Offering</ItemLink>
    //         </Item>
    //         <Item>
    //           <ItemLink to={"/"}>Tools & Resources</ItemLink>
    //         </Item>
    //         <Item>
    //           <ItemLink to={"/"}>Pricing</ItemLink>
    //         </Item>
    //       </Menu>
    //     </FlexColMenu>
    //     <FlexColLast xs={12}>
    //       <Text>
    //         ©2020 Johnston Grocke. Accountants & Financial Planners. Mortgage &
    //         Finance Services
    //         <span>
    //           <a href="/">Privacy Policy</a>
    //         </span>
    //         <span>
    //           <a href="/" target="blank" rel="noopener noreferrer">
    //             Disclaimer
    //           </a>
    //         </span>
    //       </Text>
    //     </FlexColLast>
    //   </FlexRow>
    // </NewFlexGrid>
  )
}
export default FooterMenu
