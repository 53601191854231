const primaryColor = "#F17071"
const white = "#FFFFFF"

const theme = {
  primaryColor: primaryColor,
  linkColor: primaryColor,
  btnColor: primaryColor,
  btnHoverColor: "#84004b",
  btnTextColor: white,
  peach: "#F17071",
  navy: "#2A3449",
  mobileGutter: 30,
  desktopGutter: 50,
  mainMaxWidth: 1160,
  mobileGap: 15,
  tabletGap: 20,
  desktopGap: 30,
  largeGap: 40,
  flexboxgrid: {
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 80, // em
    },
  },
}

export default theme
