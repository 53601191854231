import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "breakpoints"
import { Button } from "components/Button"

const navItemHorizontalSpacing = "18px"

export const NavItemLink = styled(Link)`
  display: block;
  font-weight: 600;
  color: ${props => props.theme.linkColor};
  padding: 0px 30px;
  width: 100%;

  @media ${device.desktop} {
    padding: 10px 15px;
    text-align: center;
    position: relative;

    :after {
      content: "";
      position: absolute;
      top: 28px;
      left: -88%;
      width: 289%;
      height: 68%;
      z-index: 100;
      display: none;
    }

    :before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 94%;
      height: 70%;
      transform: translateX(-50%) translateY(-48%);
      background: ${props => props.theme.peach};
      border-radius: 30px;
      z-index: -1;
      opacity: 0;
      transition: 120ms;
    }
    :hover {
      :before {
        opacity: 1;
      }
    }
  }

  @media ${device.large} {
    padding: 10px ${navItemHorizontalSpacing};
  }
`
export const NavBarContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;

  @media ${device.desktop} {
    padding: 0;
    margin-top: 80px;
  }
  @media ${device.large} {
    margin-top: 80px;
  }
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  height: 22px;
  width: 166px;
  z-index: 4;

  @media ${device.desktop} {
    height: 38px;
    width: 286px;
    margin-right: auto;
  }
  > svg {
    height: 100%;
    width: 100%;
    display: block;
  }
`

export const NavWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media ${device.desktop} {
    /* ::before {
      content: "";
      position: absolute;
      height: 50px;
      top: 3  0px;
      right: 0;
      margin: 0 auto;
      /* border: 1px solid red; */
      /* z-index: 100; */
      /* width: 630px; */
} 
    @media ${device.large} {
      width: 700px;
      width: 100%;
    }
  }
`

export const Nav = styled.nav`
  position: absolute;
  display: ${props => (props.isNavShown ? "block" : "none")};
  top: 0;
  left: -30px;
  width: 100vw;
  background: ${props => props.theme.navy};
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.65);
  padding-top: 90px;
  padding-bottom: 85px;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 13vw), 0 100%);
  z-index: ${props => (props.isNavShown ? "3" : "-1")};

  @media ${device.desktop} {
    clip-path: none;
    padding: 0;
    box-shadow: none;
    background: none;
    transform: translate3d(0, 0, 0);
    justify-content: flex-end;
    order: 3;
    display: flex;
    z-index: 3;
    width: 100%;
    left: 0;
  }
`

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-end;

  @media ${device.desktop} {
    flex-direction: row;
  }
`

export const NavListItem = styled.li`
  display: flex;
  align-self: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;

  @media ${device.desktop} {
    width: auto;
    padding: 0;
    position: ${props => !props.megamenu && "relative"};
  }

  span {
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: block;
    padding: 17px 0;
    font-size: 17px;

    @media ${device.desktop} {
      display: inline;
      font-size: 15px;
      border: none;
      padding: 0;
    }
    @media ${device.large} {
      font-size: 16px;
    }
  }

  :last-of-type {
    span {
      border: none;
    }
  }

  :hover {
    ${NavItemLink}:after {
      display: ${props => (props.childpages ? "block" : "none")};
    }
  }
`

export const NavIcon = styled.div`
  display: flex;
  align-items: center;
  /* padding: 0 12px; */
  max-height: 60px;
  height: 100%;
  opacity: 1;
  transition: 110ms;
  z-index: 3;
  svg {
    display: block;
    margin-left: 25px;
    height: 46px;
  }

  @media ${device.desktop} {
    padding: 0 20px;
    padding-right: 0;
  }

  @media ${device.desktop} {
    display: ${props => (props.campaign ? "flex" : "none")};
  }
`

export const DesktopPhone = styled.div`
  display: none;
  font-weight: 300;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: -0.38px;
  line-height: 24px;
  margin-left: 10px;

  @media ${device.desktop} {
    display: ${props => (props.campaign ? "block" : "none")};
  }

  span {
    font-weight: 600;
    position: relative;
    margin-left: 5px;
    margin-right: 5px;

    ::before {
      position: absolute;
      content: "";
      bottom: 3px;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${props => props.theme.primaryColor};
    }

    :hover {
      ::before {
        background: #fff;
      }
    }
  }
  span.second__text {
    font-size: 16px;
    color: #ffffff;
    letter-spacing: -0.25px;
    line-height: 24px;
    ::before {
      display: none;
    }
  }
`

export const PhoneContact = styled.a`
  display: flex;
  text-decoration: none;

  > svg {
    height: 22px;
    width: 22px;
    display: block;
  }

  :hover${DesktopPhone} {
    span:before {
      opacity: 1;
    }
  }
`
export const EmailContact = styled.a`
  display: ${props => (props.campaign ? "block" : "none")};

  @media ${device.desktop} {
    display: none;
  }
`
export const ContactButton = styled(Button)`
  display: none;
  @media ${device.desktop} {
    font-size: 16px;
    color: #ffffff;
    letter-spacing: -0.1px;
    line-height: 27px;
    display: flex;
    border-radius: 30px;
    text-transform: uppercase;
    margin-left: 15px;
    padding: 0 25px;
    border: 2px solid #f17071;
    background: #f17071;
    border-radius: 30px;
    :hover {
      border: 2px solid #fff;
    }
  }
`

export const Clickable = styled.div`
  display: block;
  height: 100%;
  /* width: 100%; */
  z-index: 4;
  display: flex;
  /* padding-left: 13px; */
  justify-content: center;
  align-items: center;
  :hover${Nav} {
    z-index: 3;
    display: flex;
  }

  @media ${device.desktop} {
    display: none;
  }
`

export const HamburgerWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 14px;
  position: relative;

  cursor: pointer;
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  transition: 0.4s ease-out;
  background: white;
  position: absolute;
  transform: ${props =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(45deg)"
      : "translate3d(0, 0, 0) rotate(0deg)"};
`

export const SecondLine = styled(FirstLine)`
  transition: 0.1s ease-out;
  opacity: ${props => (props.isNavShown ? 0 : 1)};
  top: 7px;
`

export const ThirdLine = styled(FirstLine)`
  transform: ${props =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(-45deg)"
      : "translate3d(0, 14px, 0) rotate(0deg)"};
  display: block;
`

export const LoginButton = styled(Button)`
  position: absolute;
  padding: 0;
  right: 0;
  border: none;
  height: auto;
  display: none;

  @media ${device.desktop} {
    display: flex;
    top: -60px;
  }
  @media ${device.large} {
    top: -80px;
  }

  :hover {
    .label {
      :before {
        bottom: -2px;
        opacity: 1;
      }
    }
  }
  .label {
    color: white;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 0.5px;

    :before {
      content: "";
      position: absolute;
      bottom: -6px;
      width: 100%;
      transition: 160ms;
      opacity: 0;
      height: 1px;
      background: white;
    }
  }

  .icon {
    order: 2;
    margin: 0;
    margin-left: 15px;
    height: 40px;
    width: 36px;
    border-radius: 0 0 16px 16px;
    background: ${props => props.theme.peach};
    svg {
      width: 18px;
    }
  }
`

export const StickyNav = styled.nav`
  display: ${props => (props.isMobile ? "flex" : "none")};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 10;
  background: ${props => props.theme.navy};
  box-shadow: -1px -22px 62px -32px rgba(0, 0, 0, 0.82);

  a {
    height: 100%;
    flex-basis: 25%;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 17px;

    @media (min-width: 375px) {
      padding: 0 22px;
    }
    span {
      color: white;
      font-weight: 600;
      text-align: center;
      line-height: 120%;
      font-size: 12px;

      @media (min-width: 375px) {
        font-size: 13px;
      }

      @media ${device.tablet} {
        font-size: 16px;
      }
    }

    :last-of-type {
      border: none;
    }
  }

  @media ${device.desktop} {
    display: none;
  }
`

export const Submenu = styled.div`
  display: none;
  @media ${device.desktop} {
    left: -20px;
    top: 55px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0 25px 35px 0 rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    width: 300px;

    ${NavListItem}:hover & {
      display: block;
    }

    li {
      display: flex;
      justify-content: flex-start;
      margin-left: 26px;
      margin-right: 25px;
      padding: 10px;
      position: relative;

      :before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${props => props.theme.peach};
        border-radius: 30px;
        z-index: 0;
        opacity: 0;
        transition: 120ms;
      }

      :hover {
        a {
          color: #fff !important;
        }
        :before {
          opacity: 1;
        }
      }

      :first-child {
        margin-top: 23px;
      }
      :last-child {
        margin-bottom: 25px;
      }

      a {
        font-weight: 600;
        font-size: 16px;
        color: #2a3449 !important;
        letter-spacing: -0.25px;
        line-height: 100%;
        z-index: 2;

        :before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
      }
    }
  }
`

export const MegaMenuContainer = styled.div`
  display: none;
  @media ${device.desktop} {
    display: none;
    position: absolute;
    left: 0;
    top: 55px;
    width: 100%;
    height: auto;
    bottom: -18px;
    margin: 0 auto;
    max-width: 1200px;

    ${NavListItem}:hover & {
      display: block;
      z-index: 10;
    }
  }
`
