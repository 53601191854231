import styled from "styled-components"
import { device } from "breakpoints"

export const BreadcrumbsContainer = styled.div`
  display: none;
  @media ${device.desktop} {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
  }

  @media ${device.large} {
    margin-bottom: 25px;
  }
  svg {
    height: 17px;
    display: block;
    width: 14px;
  }

  a {
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-right: 36px;
    line-height: 115%;
    position: relative;
    :before {
      content: "";
      position: absolute;
      top: 50%;
      height: 20px;
      width: 1px;
      opacity: 0.5;
      background: white;
      left: -18px;
      font-size: 30px;
      transform: translate3d(-0, -50%, 0) rotate(30deg);
    }

    :first-of-type {
      :before {
        display: none;
      }
    }
  }
`
