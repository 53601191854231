import styled from "styled-components"
import { device } from "breakpoints"
import AvenueImg from "../AvenueImg"

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1660px;
  position: relative;
  margin: 0 auto;
  padding-bottom: ${props =>
    props.internal ? "52px" : props.campaign ? "80px" : "135px"};
  background: ${props => !props.img && "#2A3449"};
  height: ${props => props.landing && "277px"};

  @media ${device.tablet} {
    padding-bottom: ${props =>
      props.internal ? "70px" : props.campaign ? "80px" : "135px"};
    height: ${props => props.landing && "400px"};
  }
  @media ${device.desktop} {
    padding-bottom: ${props =>
      props.internal ? "166px" : props.campaign ? "130px" : "300px"};
    height: ${props => props.landing && "650px"};
  }
  @media ${device.large} {
    padding-bottom: ${props =>
      props.internal ? "166px" : props.campaign ? "130px" : "350px"};
  }

  /*nav gradient*/

  ::before {
    content: "";
    background-image: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0.65) 11%,
      rgba(0, 0, 0, 0) 33%
    );

    z-index: 1;
    width: 100%;
    height: 80%;
    position: Absolute;
    top: 0;
    left: 0;
  }
`

export const HeroImage = styled(AvenueImg)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.navy};
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 13vw), 0 100%);

  @media (min-width: 1660px) {
    clip-path: polygon(0 0, 100% 0, 100% 75.5%, 0 100%);
  }

  img {
    height: ${props => props.home && "390px"};
    object-position: 20% 0 !important;
    @media ${device.desktop} {
      height: 100% !important;
      object-position: 50% 50% !important;
    }
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /*bottom gradient*/

  ::after {
    content: "";
    /* background-image: linear-gradient(0deg, #000000 35%, rgba(0, 0, 0, 0) 58%); */
    background-image: ${props =>
      props.internal
        ? "linear-gradient(11deg, rgba(0,0,0,0.35) 19%, rgba(0,0,0,0.00) 51%)"
        : props.campaign
        ? "none"
        : "linear-gradient(0deg, #000000 35%, rgba(0, 0, 0, 0) 58%);"} !important;

    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    @media (min-width: 370px) {
      background-image: linear-gradient(
        0deg,
        #000000 28%,
        rgba(0, 0, 0, 0) 58%
      );
    }

    @media (min-width: 1024px) {
      background-image: ${props =>
        props.internal
          ? "linear-gradient(8deg, rgba(0,0,0,0.65) 24%, rgba(42,52,73,0.00) 67%);"
          : "none"};
    }
  }
`

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.internal ? "90px" : "155px")};
  z-index: 1;
  max-width: ${props => (props.internal ? "350px" : "500px")};

  @media ${device.tablet} {
    margin-top: ${props => (props.internal ? "130px" : "155px")};
  }
  @media ${device.desktop} {
    margin-top: ${props =>
      props.internal ? "275px" : props.campaign ? "315px" : "110px"};
    max-width: ${props => (props.internal ? "700px" : "500px")};
  }

  @media ${device.large} {
    margin-top: ${props =>
      props.internal ? "275px" : props.campaign ? "315px" : "110px"};
  }

  h1 {
    font-size: ${props => (props.internal ? "42px" : "48px")};
    color: white;
    font-weight: 300;
    margin-bottom: ${props => (props.internal ? "0px" : "20px")};
    @media ${device.desktop} {
      margin-bottom: ${props => (props.internal ? "0px" : "30px")};
      font-size: ${props =>
        props.internal ? "60px" : props.campaign ? "60px" : "72px"};
      letter-spacing: ${props => props.campaign && "-0.94px"};
    }
  }

  p {
    color: white;
    font-size: 18px;
    @media ${device.desktop} {
      font-size: 22px;
    }
  }
`
