import React, { useState, useEffect } from "react"
import {
  SignUpSectionContainer,
  TopSection,
  SignUpTextContainer,
  SignUpFormContainer,
  SignUpForm,
  EmailInput,
  SubmitButton,
  RHSFlexCol,
  InputsWrapper,
} from "./style.js"
import { FlexRow, FlexCol } from "components/FlexGrid"
import { ReactComponent as Logo } from "../../images/johnston-grocke-logo.svg"

import { TextInput } from "../Form/TextInput"

const SignUpSection = ({ signUpData, campaign }) => {
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = data => {
    console.log(data)

    fetch("/.netlify/functions/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(res => {
        console.log(res)
      })
      .catch(error => {
        console.log(error)
      })

    setSubmitted(true)
  }

  return (
    <SignUpSectionContainer id="signup" fullWidth gap={[0]} campaign={campaign}>
      <FlexRow>
        <FlexCol xs={12}>
          <TopSection campaign={campaign}>
            <Logo className="logo" />
          </TopSection>
        </FlexCol>
        <FlexCol xs={12} md={campaign ? 12 : 6}>
          <SignUpTextContainer
            campaign={campaign}
            dangerouslySetInnerHTML={{ __html: signUpData.lefttext }}
          />
        </FlexCol>
        <RHSFlexCol xs={12} md={6} campaign={campaign}>
          <SignUpFormContainer submitted={submitted} campaign={campaign}>
            <h3>
              {submitted
                ? "Thank you. We'll be in touch soon!"
                : signUpData.title}
            </h3>
            <p>{signUpData.description}</p>
            <SignUpForm
              noValidate
              onSubmit={handleSubmit}
              submitted={submitted}
            >
              <EmailInput
                name="name"
                type="text"
                error="Please enter your name"
                placeholder="Your name..."
              />
              <EmailInput
                type="email"
                name="email"
                error="Please enter a valid email address"
                placeholder="Your email address..."
              />

              <SubmitButton submit label="Submit" fullWidth />
            </SignUpForm>
          </SignUpFormContainer>
        </RHSFlexCol>
      </FlexRow>
    </SignUpSectionContainer>
  )
}

export default SignUpSection
