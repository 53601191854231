import styled from "styled-components"
import { device } from "../../utils/breakpoints"

export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 30px;

  @media ${device.desktop} {
    order: 10;
    position: absolute;
    bottom: 15px;
    right: 0;
  }

  a {
    margin-bottom: 6px;
    :hover {
      opacity: 0.6;
      transition: 70ms;
    }

    svg {
      display: block;
      height: 23px;
      width: 23px;
      g,
      path {
        fill: #2A3449;
      }

      opacity: 0.4;

      :hover {
        opacity: 1;
        transition: 70ms;
      }
    }
  }

  li {
      margin-right: 25px;
    }
`
