import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "components/Button"

export const CustomButton1 = styled(Button)`
  border-radius: 30px;
  background: none;
  padding: 0 25px;
  height: 42px;
  font-size: 14px;
  border: 2px solid #f17071;
  color: ${props => (props.whiteText ? "#fff" : props.theme.navy)};
  text-transform: uppercase;

  @media ${device.desktop} {
    font-size: 16px;
    height: 48px;
  }

  :hover {
    background: ${props => props.theme.peach};
    color: #fff;
  }
`
export const NewsButton = styled(CustomButton1)`
  margin-left: calc(50% - 69px);
  margin-top: 68px;
  background: transparent;
  padding: 21px;
  letter-spacing: -0.09px;
  text-align: center;
  line-height: 27px;

  @media ${device.desktop} {
    padding: 23px;
    margin-left: calc(50% - 78px);
    margin-top: 10px;
    letter-spacing: -0.1px;

    :hover {
      background: #f17071;
      color: #fff;
    }
  }
`
