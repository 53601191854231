import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
import { Header } from "./Header"
import { Footer } from "./Footer"

const Layout = ({
  internal,
  ancestors,
  children,
  headerImg,
  headerTitle,
  headerText,
  preview,
  stickyNavItems,
  landing,
  campaign,
  home,
  title,
  depth,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="app-wrapper">
        <Header
          campaign={campaign}
          internal={internal}
          title={headerTitle}
          description={headerText}
          img={headerImg}
          ancestors={ancestors}
          preview={preview}
          stickyNavItems={stickyNavItems}
          landing={landing}
          home={home}
          depth={depth}
        />
        <main>{children}</main>
        <Footer preview={preview} campaign={campaign} />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
