import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const Container = styled(MainColumn)`
  margin: 10px auto;
  margin-bottom: 40px;

  @media ${device.desktop} {
    margin: 60px auto 0 auto;
  }
`
